import { InputHTMLAttributes, forwardRef } from 'react';
import { ReactI18NextChild } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { clsx } from 'clsx';

import { INPUT_ERR } from '@components/atoms/TextInput';

export interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  description?: string;
  label?: ReactI18NextChild | Iterable<ReactI18NextChild>;
  title?: string;
  containerClassName?: string;
  hasError?: boolean;
}

export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  (
    {
      description,
      label,
      title,
      containerClassName,
      className,
      hasError,
      name,
      ...rest
    },
    ref
  ) => (
    <div className="relative flex items-center">
      <div className="flex items-center">
        <input
          name={name}
          type="checkbox"
          className={twMerge(
            clsx(
              'h-5 w-5 text-black',
              'rounded-sm border-neutral-300',
              'focus:ring-secondary-900 focus:border-secondary-900',
              ' focus:ring-1 focus:ring-inset focus:ring-offset-0',
              'disabled:text-neutral-300',
              hasError && [
                ...INPUT_ERR,
                'text-negative-500',
                'focus:border-negative-500 focus:ring-negative-500',
              ],
              className
            )
          )}
          {...rest}
          ref={ref}
        />
      </div>
      {(title || label || description) && (
        <div
          className={twMerge(
            clsx(
              'ml-4 text-xs text-neutral-500',
              rest.disabled && 'text-neutral-300',
              hasError && 'text-negative-500'
            )
          )}
        >
          {title && <p>{title}</p>}
          {label && (
            <label
              htmlFor={name}
              className={twMerge(
                clsx(
                  'font-400 text-label text-sm',
                  rest.disabled && 'text-neutral-300',
                  hasError && 'text-negative-500'
                )
              )}
            >
              {label}
            </label>
          )}
          {description && <p>{description}</p>}
        </div>
      )}
    </div>
  )
);

CheckBox.displayName = 'CheckBox';
