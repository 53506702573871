import { ReactNode } from 'react';
import { Disclosure } from '@headlessui/react';
import { clsx } from 'clsx';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';

import { IFooterGroup } from 'epromo-lib-cms/types/payload-types';

import { CmsLink, CmsLinkVariant } from '@components/molecules/CmsLink';

type FooterNavAccordionItemProps = {
  groupItem: IFooterGroup;
  lastItem: boolean;
  children?: ReactNode;
};

export function FooterNavAccordionItem({
  groupItem,
  lastItem,
  children,
}: FooterNavAccordionItemProps) {
  return (
    <Disclosure as="div">
      {({ open }) => (
        <>
          <dt
            className={clsx(
              'border-white/10 text-lg',
              'border-t-2',
              lastItem && 'border-b-2',
              'px-4'
            )}
          >
            <Disclosure.Button
              className={clsx(
                'flex py-2.5',
                'w-full items-center',
                'justify-between text-left',
                'bg-secondary-600 '
              )}
            >
              <span className="text-sm font-bold uppercase text-white">
                {groupItem.title}
              </span>
              <span className=" flex h-7 items-center text-white">
                {open ? (
                  <MinusIcon
                    className={clsx('h-5 w-5 transform')}
                    aria-hidden="true"
                  />
                ) : (
                  <PlusIcon
                    className={clsx('h-5 w-5 transform')}
                    aria-hidden="true"
                  />
                )}
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className={clsx('flex flex-col gap-y-3')}>
            {children ? (
              children
            ) : (
              <ul role="list" className="mb-3 flex flex-col gap-y-1 px-4">
                {groupItem?.links?.map((linkItem) => (
                  <li key={linkItem.id}>
                    <CmsLink
                      link={linkItem.link}
                      variant={CmsLinkVariant.SECONDARY}
                    />
                  </li>
                ))}
              </ul>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
