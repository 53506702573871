import { clsx } from 'clsx';
//import dynamic from 'next/dynamic';

import { ICmsFooter } from 'epromo-lib-cms/types/payload-types';

import { FooterSocialLinks } from '@components/molecules/FooterSocialLinks';

import {
  CmsLink,
  CmsLinkSize,
  CmsLinkVariant,
} from '@components/molecules/CmsLink';

import { Container } from '@components/atoms/Container';

import { FooterNavAccordion } from '@components/organisms/FooterNavAccordion';
import { Icon } from '@components/atoms/Icon/Icon';
import { NewsletterSubscribeForm } from '@components/organisms/Footer/NewsletterSubscribeForm';

export function Footer({
  footerCopyright,
  footerContact,
  footerLinks,
  socialLinks,
}: ICmsFooter) {
  return (
    <footer
      className="bg-secondary-600 relative isolate z-20 rounded-t-3xl"
      aria-labelledby="footer-heading"
    >
      <div
        className={clsx(
          'border-white/10 md:border-b',
          'border-b-none',
          'pb-0 pt-8 md:pb-8'
        )}
      >
        <Container className="max-w-8xl">
          <div
            className={clsx(
              'flex flex-col lg:flex-row',
              'justify-start gap-y-10 lg:gap-y-0',
              'lg:items-center lg:justify-between'
            )}
          >
            <div
              className={clsx(
                'flex flex-col',
                'gap-y-6',
                'gap-x-16 md:flex-row lg:order-1'
              )}
            >
              {footerContact?.phones?.map((item) => (
                <div key={item.id} className="flex">
                  <div
                    className={clsx(
                      'flex flex-shrink-0 self-center',
                      'h-10 w-10 rounded bg-neutral-900',
                      'items-center justify-center',
                      'rounded-full'
                    )}
                  >
                    <Icon name="headSetMic" className="text-white" />
                  </div>
                  <div className="ml-4">
                    <h4 className="mb-2 text-sm text-white">{item.title}</h4>
                    <a
                      href={`tel:${item.value}`}
                      className={clsx(
                        'mt-1 text-xl font-black',
                        item.isPrivate
                          ? 'text-negative-500'
                          : 'text-business-400'
                      )}
                    >
                      {item.value}
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={clsx(
                'mt-8 text-sm leading-5',
                'text-neutral-200 md:mt-0 lg:order-2',
                'self-start'
              )}
            >
              <div>
                <p
                  className={clsx(
                    'mb-2 flex gap-x-1',
                    'justify-start lg:justify-end',
                    'text-left lg:text-right'
                  )}
                >
                  <span>{footerContact.workingHoursTitle}</span>
                  <span>{footerContact.workingHours}</span>
                </p>
                <p
                  className={clsx(
                    'flex items-center gap-x-1',
                    'justify-start md:justify-end'
                  )}
                >
                  <span>{footerContact.reachByEmailTitle}</span>{' '}
                  <a
                    href={`mailto:${footerContact.reachByEmail}`}
                    className={clsx('font-medium')}
                  >
                    {footerContact.reachByEmail}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <div
        className={clsx(
          'max-w-8xl mx-auto px-4',
          'lg:px-8',
          'pb-16 pt-8 md:pt-16'
        )}
      >
        <div
          className={clsx(
            'flex flex-col lg:flex-row',
            'flex-wrap justify-start lg:justify-between'
          )}
        >
          <div className="block md:hidden">
            {footerLinks.groups && (
              <FooterNavAccordion
                groups={footerLinks.groups}
                socialLinks={socialLinks}
              />
            )}
          </div>
          <div
            className={clsx(
              'hidden auto-cols-fr grid-flow-col md:grid',
              'gap-x-16'
            )}
          >
            {footerLinks.groups?.map((group, groupIdx) => (
              <div key={group.id}>
                <h3
                  className={clsx(
                    'text-sm font-semibold uppercase',
                    'leading-6 text-white'
                  )}
                >
                  {group.title}
                </h3>
                <ul role="list" className="mt-4 flex flex-col gap-y-1">
                  {group?.links?.map((linkItem) => (
                    <li key={linkItem.id}>
                      <CmsLink
                        link={linkItem.link}
                        variant={CmsLinkVariant.SECONDARY}
                      />
                    </li>
                  ))}
                </ul>
                {footerLinks.groups.length - 1 === groupIdx && (
                  <FooterSocialLinks
                    socialLinks={socialLinks}
                    className="hidden md:flex"
                  />
                )}
              </div>
            ))}
          </div>
          <NewsletterSubscribeForm />
        </div>
      </div>

      <div className="border-t border-white/10 py-8">
        <Container className="max-w-8xl">
          <div className={clsx('md:flex md:items-center md:justify-between')}>
            <div className="flex gap-x-10 md:order-2 flex-wrap gap-y-4">
              {footerCopyright.links?.map((item) => (
                <CmsLink
                  key={item.id}
                  link={item.link}
                  size={CmsLinkSize.SMALL}
                  variant={CmsLinkVariant.SECONDARY}
                  target={item.link.newTab ? '_blank' : '_self'}
                  id={item.link.htmlAttId}
                />
              ))}
            </div>
            <p
              className={clsx(
                'mt-8 text-xs leading-5',
                'text-neutral-300 md:order-1 md:mt-0'
              )}
            >
              &copy; {new Date().getFullYear()} {footerCopyright.title}
            </p>
          </div>
        </Container>
      </div>
    </footer>
  );
}
