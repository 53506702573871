import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { twMerge } from 'tailwind-merge';
import Link from 'next/link';
import { object, string, bool } from 'yup';

import { useSubscribeToNewsLetter } from 'epromo-lib/mutations';
import { useInternalLinks } from 'epromo-lib/hooks/useInternalLinks';

import { CheckBox } from '@components/molecules/CheckBox';
import { FormField } from '@components/molecules/FormField';
import { Button } from '@components/atoms/Button';
import { Alert, AlertVariant } from '@components/atoms/Alert';
import { Icon } from '@components/atoms/Icon/Icon';

interface NewsletterSubscriptionFormData {
  email: string;
  agreedToTerms: boolean;
  agreedToMarketing?: boolean;
}

export function NewsletterSubscribeForm() {
  const { t } = useTranslation('common');
  const {
    isPending,
    mutateAsync,
    isSuccess,
    reset: resetMutation,
  } = useSubscribeToNewsLetter();

  const { register, control, handleSubmit, reset } =
    useForm<NewsletterSubscriptionFormData>({
      resolver: yupResolver(
        object({
          email: string()
            .email(t('invalidEmail', { ns: 'common' }))
            .required(t('required', { ns: 'common' })),
          agreedToTerms: bool().oneOf([true]).required(),
        })
      ),
      defaultValues: {
        email: '',
        agreedToTerms: false,
        agreedToMarketing: false,
      },
    });
  const { privacyPolicyUrlSlug, marketingTermsUrlSlug } =
    useInternalLinks();

  const onSubmit = async (data: NewsletterSubscriptionFormData) => {
    resetMutation();
    await mutateAsync(data);
  };

  return (
    <div className="mt-10 w-full sm:max-w-sm lg:mt-0 xl:mt-0">
      <h3 className="text-xl font-black leading-6 text-white">
        {t('subscribeNewsLetterTitle')}
      </h3>
      <p className="mt-6 text-sm leading-6 text-gray-300">
        {t('subscribeNewsLetterBenefit')}
      </p>
      <form
        className="mt-6 flex w-full flex-col gap-y-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className={twMerge(
            'mt-1 flex justify-between',
            'overflow-hidden rounded-full bg-neutral-900 px-2 py-1.5'
          )}
        >
          <div
            className={twMerge(
              'relative flex flex-grow',
              'items-stretch focus-within:z-10',
              'max-w-md flex-1'
            )}
          >
            <input
              type="email"
              id="email"
              className={twMerge(
                'block w-full ',
                'border-none bg-neutral-900 text-neutral-400',
                'sm:text-sm',
                'ring-0 focus:ring-0',
                'outline-none focus:outline-none'
              )}
              placeholder={t('yourEmailAddress')}
              {...register('email')}
            />
          </div>

          <Button
            type="submit"
            className={twMerge(
              'bg-negative-500 text-white',
              'rounded-3xl px-4 text-sm'
            )}
            disabled={isPending}
            loadingIndicator="icon"
          >
            {t('subscribe')}
          </Button>
        </div>
        <FormField id="email" control={control} />
        <Controller
          name="agreedToTerms"
          control={control}
          render={({ field, fieldState }) => {
            const checkboxLabel = (
              <span
                className={twMerge(
                  '-ml-2 block text-neutral-300',
                  !!fieldState.error && 'text-inherit'
                )}
              >
                <Trans i18nKey="agreeToNewsletterConditions">
                  agree with
                  <Link className="font-bold" href={privacyPolicyUrlSlug}>
                    privacy terms
                  </Link>
                </Trans>
              </span>
            );

            return (
              <div
                className={twMerge(
                  'flex flex-row items-center gap-2 ',
                  isSuccess && 'hidden'
                )}
              >
                <CheckBox
                  checked={field.value}
                  onChange={field.onChange}
                  hasError={!!fieldState.error}
                  label={checkboxLabel}
                  className={twMerge(
                    'bg-secondary-600 m-2 border-neutral-300',
                    'checked:border-neutral-300',
                    'checked:hover:border-neutral-300 focus:ring-white',
                    fieldState.error?.message && 'border-negative-500'
                  )}
                />
              </div>
            );
          }}
        />
        <Controller
          name="agreedToMarketing"
          control={control}
          render={({ field, fieldState }) => {
            const checkboxLabel = (
              <span
                className={twMerge(
                  '-ml-2 block text-neutral-300',
                  !!fieldState.error && 'text-inherit'
                )}
              >
                <Trans i18nKey="agreeToNewsletterMarketing">
                  agree with
                  <Link className="font-bold" href={marketingTermsUrlSlug}>
                    marketing
                  </Link>
                  terms
                </Trans>
              </span>
            );

            return (
              <div
                className={twMerge(
                  'flex flex-row items-center gap-2 ',
                  isSuccess && 'hidden'
                )}
              >
                <CheckBox
                  checked={field.value}
                  onChange={field.onChange}
                  hasError={!!fieldState.error}
                  label={checkboxLabel}
                  className={twMerge(
                    'bg-secondary-600 m-2 border-neutral-300',
                    'checked:border-neutral-300',
                    'checked:hover:border-neutral-300 focus:ring-white',
                    fieldState.error?.message && 'border-negative-500'
                  )}
                />
              </div>
            );
          }}
        />
        {!isPending && isSuccess && (
          <Alert
            icon={<Icon name="successFilled" />}
            message={t('thanksSubscribing')}
            type={AlertVariant.SUCCESS}
            className="text-positive-400 w-full max-w-none bg-transparent"
          />
        )}
      </form>
    </div>
  );
}
