import { NextRouter } from 'next/router';

import {
  IInnerPage,
  ILink,
  IMappedCmsBreadCrumbs,
} from './types/payload-types';

export function resolveLink(link?: ILink) {
  if (!link) {
    return '';
  }
  if (link.url) {
    return link.url;
  }
  if (!link.reference) {
    return '';
  }

  switch (link?.reference.relationTo) {
    case 'pages': {
      return link.reference.value.pSlug
        ? `/${link.reference.value.pSlug}`
        : `/${link.reference.value.slug}`;
    }
  }
  return '/';
}

export function resolveLinkText(link?: ILink) {
  if (!link) {
    return '';
  }
  if (link.title) {
    return link.title;
  }
  if (link.label) {
    return link.label;
  }

  switch (link?.reference?.relationTo) {
    case 'pages': {
      return link.reference.value.pTitle
        ? link.reference.value.pTitle
        : link.reference.value.title;
    }
  }

  return 'Plačiau';
}

export const resolveBreadCrumbs = (page: IInnerPage, router: NextRouter) => {
  const result = {
    links: [],
  } as IMappedCmsBreadCrumbs;

  if (
    page.cmsBreadCrumbs &&
    page.cmsBreadCrumbs.links &&
    page.cmsBreadCrumbs.links.length > 0
  ) {
    page.cmsBreadCrumbs.links.forEach((breadLink) => {
      if (breadLink.link.reference) {
        result.links.push({
          urlSlug: `/${router.locale}/${breadLink.link.reference.value.slug}`,
          urlSlugEn: `/en/${breadLink.link.reference.value.slug}`,
          id: `bl${breadLink.id}`,
          name: `${resolveLinkText(breadLink.link)}`,
        });
      }
    });
  } else {
    result.links.push({
      urlSlug: `/${router.locale}/${page.slug}`,
      urlSlugEn: `/en/${page.slug}`,
      id: 'bl2',
      name: `${page.title}`,
    });
  }
  return result;
};
