import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { IFooterSocialLink } from 'epromo-lib-cms/types/payload-types';

import { CmsLink } from '@components/molecules/CmsLink';
import { Icon } from '@components/atoms/Icon';

const SocIconsMap: Record<string, () => JSX.Element> = {
  fb: () => <Icon name="footerFb" />,
  youtube: () => <Icon name="footerYt" />,
  instagram: () => <Icon name="footerInsta" />,
};

export type FooterSocialLinksProps = {
  socialLinks: IFooterSocialLink[];
  className?: string;
};
export function FooterSocialLinks({
  socialLinks,
  className,
}: FooterSocialLinksProps) {
  return (
    <ul
      role="list"
      className={twMerge('mt-6 flex', 'items-center gap-x-4', className)}
    >
      {socialLinks?.map((iconLinkItem) => (
        <li
          key={iconLinkItem.id}
          className={clsx(
            'h-10 w-10',
            'bg-neutral-900 hover:bg-neutral-800',
            'cursor-pointer rounded rounded-full',
            'flex items-center justify-center'
          )}
        >
          <CmsLink link={iconLinkItem.link}>
            {SocIconsMap[iconLinkItem.iconType] &&
              SocIconsMap[iconLinkItem.iconType]()}
          </CmsLink>
        </li>
      ))}
    </ul>
  );
}
