import { Fragment } from 'react';
import {
  IFooterGroup,
  IFooterSocialLink,
} from 'epromo-lib-cms/types/payload-types';

import { FooterNavAccordionItem } from '@components/organisms/FooterNavAccordion';
import { FooterSocialLinks } from '@components/molecules/FooterSocialLinks';

export type FooterNavAccordionProps = {
  groups: IFooterGroup[];
  socialLinks: IFooterSocialLink[];
};

export function FooterNavAccordion({
  groups,
  socialLinks,
}: FooterNavAccordionProps) {
  return (
    <dl className="-ml-4 -mr-4">
      {groups.map((group, groupIdx) => (
        <Fragment key={`fna-${group.id}`}>
          {groups.length - 1 === groupIdx ? (
            <FooterNavAccordionItem
              key={group.title}
              groupItem={group}
              lastItem={groups.length - 1 === groupIdx}
            >
              <FooterSocialLinks
                socialLinks={socialLinks}
                className="flex px-4 md:hidden"
              />
            </FooterNavAccordionItem>
          ) : (
            <FooterNavAccordionItem
              key={group.title}
              groupItem={group}
              lastItem={groups.length - 1 === groupIdx}
            />
          )}
        </Fragment>
      ))}
    </dl>
  );
}
