import Link from 'next/link';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { clsx } from 'clsx';

import { ILink } from 'epromo-lib-cms/types/payload-types';
import { resolveLink, resolveLinkText } from 'epromo-lib-cms/utils';

export interface CmsLinkProps
  extends Omit<
    DetailedHTMLProps<
      AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'href'
  > {
  link: ILink;
  size?: CmsLinkSize;
  variant?: CmsLinkVariant;
  prefetch?: boolean;
}

export enum CmsLinkSize {
  SMALL,
  NORMAL,
}

export enum CmsLinkVariant {
  PRIMARY,
  SECONDARY,
}

const SIZE_MAPS: Record<CmsLinkSize, string> = {
  [CmsLinkSize.SMALL]: clsx('text-xs'),
  [CmsLinkSize.NORMAL]: clsx('text-sm'),
};

const VARIANT_MAPS: Record<CmsLinkVariant, string> = {
  [CmsLinkVariant.PRIMARY]: clsx('text-white hover:text-neutral-300'),
  [CmsLinkVariant.SECONDARY]: clsx('text-neutral-300 hover:text-white'),
};

export function CmsLink({
  link,
  children,
  className,
  size = CmsLinkSize.NORMAL,
  variant = CmsLinkVariant.PRIMARY,
  prefetch,
  ...rest
}: CmsLinkProps) {
  const url = resolveLink(link);
  const linkText = resolveLinkText(link);
  const newTabProps = link?.newTab
    ? {
        target: '_blank',
        rel: 'noreferrer',
      }
    : {};

  if (link.type === 'custom') {
    return (
      <a
        href={url}
        className={twMerge(SIZE_MAPS[size], VARIANT_MAPS[variant], className)}
        {...newTabProps}
        {...rest}
      >
        {children}
        {!children && linkText}
      </a>
    );
  }

  return (
    <Link href={url} legacyBehavior {...newTabProps} prefetch={prefetch}>
      <a
        className={twMerge(SIZE_MAPS[size], VARIANT_MAPS[variant], className)}
        {...rest}
      >
        {children}
        {!children && linkText}
      </a>
    </Link>
  );
}
